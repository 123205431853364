import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import VerticalCard from "../cards/courseCards/VerticalCard";
import moment from "moment";
import { Spinner } from "react-bootstrap";

//importing styles
import "../../../assets/scss/components/paginate/paginate.scss";
import {
  selectClassList,
  setClassList,
} from "../../../redux/classSlice";
import { getClassService } from "../../../services/dashboard.service";
import NoData from "../noData/NoData";

function Items({ currentItems ,slug }) {
  return (
    <>
      {currentItems?.length>0?
      <div className="card-container d-flex flex-wrap content-center-small gap-5">
        {currentItems?.map((item) => {
          return (
            <div>
              <VerticalCard cardContent={item} pageSlug={slug} />
            </div>
          );
        })}
      </div>:
        <NoData page = "class-pagination" slug={slug}/>
      }
    </>
  );
}


const Paginate = ({ totalItem, slug ,activeTab ,selectedCategory}) => {
  const dispatch = useDispatch();
  const Classes = useSelector(selectClassList);
  
  const date = moment(new Date()).format("YYYY-MM-DD");

 

  const ClassesList = Classes?.map((item) =>item);

  const totalPage = Number(totalItem) / 12;

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading ,setLoading] = useState(false)

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  var itemsPerPage = 1;
  const pageCount = Math.ceil(Number(totalPage) / itemsPerPage);
  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {

    sessionStorage.setItem(`${slug}_currentPage`, event.selected);
    const newOffset = (event.selected * itemsPerPage) % totalPage;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);

    const result = await getClassService({
      filter: slug,
      page: event.selected + 1,
      perPageItem: 12,
      language: activeTab,
      category: selectedCategory,
      up_coming_date: date,
      up_coming_classes: (slug?.toLowerCase() === 'onlive' ||slug?.toLowerCase() === 'todays-classes' )

    });
    dispatch(setClassList(result?.data?.classes?.data));
  };


  useEffect(() => {
    // Simulate the first page load
    setLoading(true)
    const savedPage = sessionStorage.getItem(`${slug}_currentPage`);
    const initialPage = savedPage ? parseInt(savedPage, 10) : 0;
    const fetchInitialPage = async () => {
      const result = await getClassService({
        filter: slug,
        page: initialPage + 1,
        perPageItem: 12,
        language: activeTab,
        category: selectedCategory,
        up_coming_date: date,
        up_coming_classes: (slug?.toLowerCase() === 'onlive' ||slug?.toLowerCase() === 'todays-classes' )
      });
      dispatch(setClassList(result?.data?.classes?.data));
      setCurrentPage(initialPage);
      setLoading(false)
    };
    fetchInitialPage();
  }, [dispatch, slug, date ,activeTab, selectedCategory ,slug]);

  // const handleGetItems = async () => {
  //   const result = await getClassService({
  //     filter: getSlug[0]?.slug,
  //     perPageItem: itemsPerPage + 1,
  //   });
  //   dispatch(setOnLiveClasses(result?.data?.classes?.data));
  // };

  return (
    <>
      {isLoading?
      <div className='d-flex justify-content-center align-items-center my-5 w-100'>
          <Spinner className='mx-auto' animation="border" />
      </div>
      :
        <Items currentItems={ClassesList} slug={slug} />}
      <div className={"d-flex justify-content-center" }>
      
      {/* <div className={currentPage == pageCount - 1 && currentPage === 0 ?"d-flex justify-content-center" :''}> */}
        <ReactPaginate
          breakLabel="..."
          nextLabel={currentPage === pageCount - 1 ? null : "Next"}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={currentPage === 0 ? null : "Previous"}
          renderOnZeroPageCount={null}
          className="paginationComponent"
          containerClassName={"pagination"}
          previousClassName={currentPage === 0 ? "previous hide-arrow" : "previous"}
          nextClassName={currentPage === pageCount - 1 ? " hide-next-arrow" : "next"}
          forcePage={currentPage}  // Ensure the current page is reflected in the UI
        />
      </div>
      
    </>
  );
};

export default Paginate;